<template>
	<var-style-provider :style-vars="{ '--cell-font-size': '1.5rem' }">
		<div class="var-elevation--2 p-3 mb-3">
			<div
				:id="actionable ? 'info_cell' : ''"
				class="d-flex justify-content-start align-items-center"
				@click="$emit('onCommitClick', hash)"
			>
				<var-image
					width="36px"
					height="36px"
					fit="cover"
					radius="50%"
					:src="avatar"
				/>
				<var-cell
					:title="message.substring(0, 40) + '...'"
					:description="hashShort + ', by ' + user + ' at ' + date"
				/>
			</div>
		</div>
	</var-style-provider>
</template>

<script>
export default {
	name: "CommitCell",
	props: {
		hash: {
			type: String,
			required: true
		},
		message: {
			type: String,
			required: true
		},
		user: {
			type: String,
			required: true
		},
		avatar: {
			type: String,
			required: true
		},
		hashShort: {
			type: String,
			required: true
		},
		date: {
			type: String,
			required: true
		},
		actionable: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	emits: ['onCommitClick']
}
</script>

<style scoped>

#info_cell {
	cursor: pointer;
}

</style>