<template>
	<var-skeleton :rows="20" :loading="loading">
		<var-row>
			<breadcrumbs current-item-title="Deployment Details" :items="breadcrumbItems"/>
		</var-row>
		<var-row :gutter="20">
			<var-col :span="12">
				<var-cell>Start Commit</var-cell>
				<commit-cell
					v-if="deployment.start_commit"
					:hash="deployment.start_commit.sha"
					:message="deployment.start_commit.message"
					:hash-short="deployment.start_commit.sha_short"
					:user="deployment.start_commit.author"
					:avatar="deployment.start_commit.author_avatar"
					:date="deployment.start_commit.date"
					:actionable="false"
				/>
				<no-commit-cell
					v-else
					message="The very first commit..."
					:actionable="false"
				/>
			</var-col>
			<var-col :span="12">
				<var-cell>End Commit</var-cell>
				<commit-cell
					:hash="deployment.end_commit.sha"
					:message="deployment.end_commit.message"
					:hash-short="deployment.end_commit.sha_short"
					:user="deployment.end_commit.author"
					:avatar="deployment.end_commit.author_avatar"
					:date="deployment.end_commit.date"
					:actionable="false"
				/>
			</var-col>
		</var-row>
		<div class="var-elevation--4">
			<var-row>
				<var-col :span="span">
					<var-cell icon="map-marker">
						Deployed to {{ deployment.server }}
					</var-cell>
				</var-col>

				<var-col v-if="deployment.status === 'finished' || deployment.status === 'failed'" :span="span">
					<var-cell v-if="deployment.status === 'finished'" icon="cake-variant">
						Finished at {{ deployment.finished_at }}
					</var-cell>
					<var-cell v-else-if="deployment.status === 'failed'" icon="cake-variant">
						Failed at {{ deployment.finished_at }}
					</var-cell>
				</var-col>

				<var-col v-if="deployment.status === 'finished' || deployment.status === 'failed'" :span="span">
					<var-cell icon="fire">
						Deployment took {{ deployment.duration }}
					</var-cell>
				</var-col>

				<var-col :span="span" class="text-end">
					<var-cell v-if="deployment.deployed_by">
						Deployed by {{ deployment.deployed_by }}
					</var-cell>
					<var-cell v-else>
						Automatic Deployment
					</var-cell>
				</var-col>
			</var-row>
		</div>
		<div id="content_centered" class="text-center p-4 mt-4">
			<h2 v-if="deployment.status === 'finished'" class="mb-3">Deployment has completed successfully!</h2>
			<h2 v-else-if="deployment.status === 'running'" class="mb-3">Deployment is in progress.</h2>
			<h2 v-else-if="deployment.status === 'failed'" class="mb-3">Failed to deploy!</h2>
			<var-button type="info" @click="viewReport">View Deployment Report</var-button>
		</div>
	</var-skeleton>
</template>

<script>
import request from "../request";
import CommitCell from "../components/CommitCell";
import NoCommitCell from "../components/NoCommitCell.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
	name: "DeploymentDetails",
	components: {Breadcrumbs, NoCommitCell, CommitCell},
	created() {
		this.getDeploymentDetails()
	},
	data() {
		return {
			loading: false,
			breadcrumbItems: [
				{
					title: 'Projects',
					to: 'Projects',
					params: {}
				}
			],
			deployment: {}
		}
	},
	computed: {
		span() {
			return (this.deployment.status === 'finished' || this.deployment.status === 'failed') ? 6 : 12
		}
	},
	methods: {
		getDeploymentDetails() {
			this.loading = true
			request.get(process.env.VUE_APP_BASE_URL + `/projects/${this.$route.params.project_id}/deployments/${this.$route.params.deployment_id}/details`)
				.then(data => {
					this.deployment = data.data
					this.breadcrumbItems.push({
						title: this.deployment.project,
						to: 'ProjectDetails',
						params: {
							id: this.$route.params.project_id
						}
					})
					this.loading = false
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		viewReport() {
			this.$router.push({
				name: 'DeploymentReport',
				params: {
					project_id: this.$route.params.project_id,
					deployment_id: this.$route.params.deployment_id
				}
			})
		}
	}
}
</script>

<style scoped>

#content_centered {
	width: 50%;
	margin: 0 auto;
}

</style>